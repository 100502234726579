<template>
  <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
    <div class="logo">
      <el-image :src="require(`~/assets/images/records/${img}`)" fit="contain">
        <div slot="error" class="image-slot">
          <i class="el-icon-picture-outline"></i>
        </div>
      </el-image>
    </div>
    <div class="text">
      <div class="figures">
        {{ figure }}
      </div>
      <div class="description">{{ description }}</div>
    </div>
  </el-col>
</template>

<script>
export default {
  props: {
    img: { type: String, default: '' },
    figure: { type: String, default: '' },
    description: { type: String, default: '' }
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
@import '~/assets/scss/main';

.logo {
  width: 68px;
  height: 68px;
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  @include for-phone-portrait {
    height: 32px;
    width: 32px;
    .el-image {
      height: 32px;
      width: 32px;
    }
  }
  @include for-phone-landscape {
    height: 32px;
    width: 32px;
    .el-image {
      height: 32px;
      width: 32px;
    }
  }
  @include for-tablet-portrait {
    height: 48px;
    width: 48px;
    .el-image {
      height: 48px;
      width: 48px;
    }
  }
  @include for-tablet-landscape {
    height: 48px;
    width: 48px;
    .el-image {
      height: 48px;
      width: 48px;
    }
  }
}

.text {
  font-size: 24px;
  font-weight: bold;
  line-height: 25px;
  @include for-phone-portrait {
    font-size: 16px;
    line-height: 25px;
  }
  @include for-phone-landscape {
    font-size: 16px;
    line-height: 25px;
  }
  .description {
    margin-top: 8px;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    @include for-phone-portrait {
      margin-top: 0;
    }
    @include for-phone-landscape {
      margin-top: 0;
    }
  }
}
</style>
