<script type="text/jsx">
import SideNavigationLink from './SideNavigationLink'

export default {
  components: {
    sideNavigationLink: SideNavigationLink
  },
  props: {
    mode: {
      type: String,
      default: 'auto',
      required: false
    },
    showActiveBar: {
      type: Boolean,
      default: true,
      required: false
    },
    showBarStrip: {
      type: Boolean,
      default: true,
      required: false
    },
    heightPerTab: {
      type: Number,
      default: 35,
      required: false
    },
    activeTabIndex: {
      type: Number,
      required: true
    },
    labels: {
      type: Array,
      default: () => {
        return []
      },
      required: false
    },
    activeTabColor: {
      type: String,
      default: 'red',
      required: false
    },
    inactiveTabColor: {
      type: String,
      default: '#7C8099',
      required: false
    },
    activeTabBgColor: {
      type: String,
      default: '#FAF8F5',
      required: false
    },
    inactiveTabBgColor: {
      type: String,
      default: '#FAF8F5',
      required: false
    },
    activeBarBgColor: {
      type: String,
      default: '#4292FF',
      required: false
    },
    barStripBgColor: {
      type: String,
      default: '#CBCCD6',
      required: false
    },
    containerBgColor: {
      type: String,
      default: '#FAF8F5',
      required: false
    }
  },
  data: () => {
    return {}
  },
  methods: {
    onTabClick(evt) {
      this.$emit('tabChange', { index: evt.index })
    },
    getNavStyle(index) {
      return {
        height: `${this.heightPerTab}px`,
        display: 'flex',
        alignItems: 'center',
        color:
          this.activeTabIndex === index
            ? this.activeTabColor
            : this.inactiveTabColor,
        backgroundColor:
          this.activeTabIndex === index
            ? this.activeTabBgColor
            : this.inactiveTabBgColor
      }
    },
    getNavFontWeight(index) {
      return this.activeTabIndex === index ? 'active-nav' : 'inactive-nav'
    },
    getActiveBarStyle() {
      const activeBarTranslateY = this.heightPerTab * this.activeTabIndex

      return {
        visibility: this.showActiveBar ? 'visible' : 'hidden',
        height: `${this.heightPerTab}px`,
        transform: `translateY(${activeBarTranslateY}px)`,
        backgroundColor: this.activeBarBgColor
      }
    },
    getActiveBarStripStyle() {
      return {
        visibility: this.showBarStrip ? 'visible' : 'hidden',
        backgroundColor: this.barStripBgColor
      }
    },
    getSideNavContainerStyle() {
      return {
        backgroundColor: this.containerBgColor
      }
    }
  },
  render(createElement) {
    let tobeRenderedNavSlots = []
    if (this.mode === 'auto') {
      tobeRenderedNavSlots = this.labels.map((label, index) => {
        return (
          <div style={this.getNavStyle(index)}>
            <p class={this.getNavFontWeight(index)}>{label}</p>
          </div>
        )
      })
    } else {
      const customeNavSlots = this.$slots.default
      tobeRenderedNavSlots = customeNavSlots.filter(
        (component) => component.tag !== undefined
      )
    }
    const panes = tobeRenderedNavSlots.map((component, index) => {
      return (
        <side-navigation-link
          index={index}
          isActive={this.activeTabIndex === index}
          onTabClick={this.onTabClick}
        >
          {component}
        </side-navigation-link>
      )
    })
    return (
      <div class="side-nav-container" style={this.getSideNavContainerStyle()}>
        <div class="active-bar" style={this.getActiveBarStyle()} />
        <div class="active-bar-strip" style={this.getActiveBarStripStyle()} />
        <div class="side-nav-panes">{panes}</div>
      </div>
    )
  }
}
</script>
<style lang="scss" scoped>
@import '~/assets/scss/theme/fontTypes.scss';
@import '~/assets/scss/theme/color.scss';

.side-nav-container {
  position: relative;
}

.active-nav {
  @include overPassFamily(
    $--font-size-medium,
    $--font-line-height-17,
    $--font-weight-semi-bold,
    $--letter-spacing-normal
  );
}

.inactive-nav {
  &:hover {
    color: $--color-primary-light-8;
  }
  @include overPassFamily(
    $--font-size-medium,
    $--font-line-height-17,
    $--font-weight-regular,
    $--letter-spacing-normal
  );
}

.active-bar-strip {
  position: absolute;
  left: 0;
  width: 3px;
  height: 100%;
  background-color: $--color-slate-light-4;
  top: 0px;
}

.active-bar {
  width: 3px;
  background: $--color-primary;
  position: absolute;
  left: 0;
  z-index: 1;
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.side-nav-panes {
  margin-left: 14px;
}
</style>
