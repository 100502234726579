<template>
  <transition name="fade">
    <div v-if="show">
      <div class="scroll-to-top" @click="scrollToTop">
        <img class="logo chevron" src="~/assets/images/scrollToTopArrow.svg" />
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      triggerPoint: 300
    }
  },
  beforeMount() {
    window.addEventListener('scroll', this.decideScrollVisible)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.decideScrollVisible)
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
    decideScrollVisible() {
      const currentPositon = document.documentElement.scrollTop
      if (currentPositon >= this.triggerPoint) {
        this.show = true
        return
      }
      this.show = false
    }
  }
}
</script>

<style scoped lang="scss">
@import '~/assets/scss/main';

.scroll-to-top {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 62px;
  width: 62px;
  border-radius: 38.4px;
  background-color: rgba(203, 204, 214, 0.6);
  cursor: pointer;
  @include for-phone-portrait {
    height: 56px;
    width: 56px;
  }
  @include for-phone-landscape {
    height: 56px;
    width: 56px;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.chevron {
  width: 27.36px;
  height: 16.9px;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
