<template>
  <div class="fifth">
    <el-row>
      <el-col :xs="24" :sm="20" :md="10" :lg="8" :xl="8" data-aos="fade-up">
        <div class="page-title">14+ successful partnerships</div>
        <div class="sub-content">
          <div class="sub-text">
            Long term partnerships to grow together with developments in
            finance, tech & building products for diverse market segments.
          </div>
          <nuxt-link :to="'/contactUs'">
            <el-button type="primary" size="large" class="hide-phone"
              >Partner With Us</el-button
            >
          </nuxt-link>
        </div>
      </el-col>
      <nuxt-link :to="'/contactUs'">
        <el-button type="primary" class="show-phone">
          Partner With Us
        </el-button>
      </nuxt-link>
      <el-col
        :xs="24"
        :sm="18"
        :md="{ span: 13 }"
        :lg="{ span: 10, offset: 2 }"
        :xl="{ span: 10, offset: 2 }"
      >
        <div class="partner-carousel">
          <div class="blue-box top"></div>
          <el-carousel
            ref="carousel"
            arrow="never"
            indicator-position="outside"
            trigger="click"
            :interval="8000"
            :autoplay="false"
            data-aos="zoom-in"
            data-aos-delay="200"
            @change="activeItem"
          >
            <template v-for="(item, index) in data">
              <el-carousel-item :key="index" :name="item.name">
                <el-card class="partner-card" shadow="always">
                  <div class="testimonial">{{ item.description }}</div>
                  <div class="person-info">
                    <!-- <el-image
                      class="image"
                      :src="require(`~/assets/images/records/${item.image}`)"
                      fit="contain"
                    >
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                    </el-image>-->
                    <div class="status">
                      <div class="name">{{ item.name }}</div>
                      <div class="company">{{ item.status }}</div>
                    </div>
                  </div>
                </el-card>
              </el-carousel-item>
            </template>
          </el-carousel>
          <div class="item-navigate">
            <el-button
              type="text-black"
              btn-min-width="false"
              @click="changeItem('prev')"
            >
              <chevron-left />
            </el-button>
            <el-button
              type="text-black"
              btn-min-width="false"
              @click="changeItem('next')"
            >
              <chevron-right />
            </el-button>
          </div>
        </div>
        <div class="partner-navigation">
          <div class="hading">Digital Co-lending Partners</div>
          <div class="partner-items">
            <div
              v-for="(item, index) in partnerUrls"
              :key="index"
              class="item"
              data-aos="fade-up"
              :data-aos-delay="200 * index"
            >
              <el-tooltip
                v-if="item.url !== 'empty'"
                :content="'Visit ' + item.url"
                placement="bottom"
                :open-delay="200"
              >
                <a shadow="hover" class="card" :href="item.url" target="_blank">
                  <el-image
                    class="logo"
                    :src="require(`~/assets/images/partners/${item.logo}`)"
                    fit="contain"
                  >
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </a>
              </el-tooltip>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <div class="site-info">
      <div class="copyright">
        &copy; Kisetsu Saison Finance India Private Limited 2021.
      </div>
      <div class="trademark">
        Credit Saison is the trade name of Kisetsu Saison Finance India Private
        Limited (CIN: U65999KA2018FTC113783), a non-banking finance company
        (NBFC) registered with the RBI.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: [
        {
          id: 1,
          name: 'Lizzie Chapman',
          logo: 'ZestMoney.svg',
          image: 'Gaurav_Hinduja.png',
          description: `Our partnership with Credit Saison is greatly aligned in terms of our goals for credit in India and how it is offered. An excellent learning opportunity for us and brings us closer to our vision of enabling affordable credit to over 300 million Indian households that are currently deprived of it.`,
          status: `CEO & Co-Founder, ZestMoney`
        },
        {
          id: 1,
          name: 'G K Shettigar',
          logo: 'NeoGrowth.svg',
          image: 'Gaurav_Hinduja.png',
          description: `The Credit Saison team’s professionalism, promptness and agility is personified in their dealings with us and speaks volumes about the reputation of the brand.  We reckon that they will soon establish themselves as a formidable name that will change the landscape of MSME funding in India.`,
          status: `SVP- Treasury, NeoGrowth Credit`
        },
        {
          id: 2,
          name: 'Prasad Shahane',
          logo: 'Rupeek.svg',
          image: 'Gaurav_Hinduja.png',
          description: `The team at Credit Saison understands the needs of a high-growth Fintech. Presha and team helped us through quick decisions, deep underwriting expertise and a willingness to iron out a middle path to make a deal. Looking forward to a long engagement in the years to come.`,
          status: `Head of Lender Partnerships, Rupeek`
        }
      ],
      partnerUrls: [
        {
          url: 'https://www.zestmoney.in/',
          logo: 'ZestMoney.png'
        },
        {
          url: 'https://www.gopaysense.com/',
          logo: 'PaySense.png'
        },
        {
          url: 'https://capitalfloat.com/',
          logo: 'CapitalFloat.png'
        },
        {
          url: 'https://www.moneytap.com/',
          logo: 'MoneyTap.png'
        },
        {
          url: 'https://www.earlysalary.com/',
          logo: 'EarlySalary.png'
        },
        {
          url: 'https://rupeek.com/',
          logo: 'Rupeek.svg'
        },
        {
          url: 'https://www.kredx.com/',
          logo: 'kredx.svg'
        },
        {
          url: 'https://progcap.com/',
          logo: 'progap.png'
        },
        {
          url: 'https://www.smecorner.com/',
          logo: 'SME_Corner.png'
        },
        {
          url: 'https://moneyview.in/',
          logo: 'MoneyView.png'
        },
        {
          url: 'https://www.stashfin.com/',
          logo: 'StashFinLogo@2x.png'
        },
        {
          url: 'https://www.lendingkart.com/',
          logo: 'lendingKart.png'
        },
        {
          url: 'https://cred.club/',
          logo: 'cred.png'
        },
        {
          url: 'https://udaancapital.com/',
          logo: 'UdaanCapital.png'
        },
        {
          url: 'https://indialends.com/',
          logo: 'IndiaLends.png'
        },
        {
          url: 'https://www.wishfin.com/',
          logo: 'WishFin.png'
        },
        {
          url: 'empty', // empty for grid
          logo: 'cred.png'
        },
        {
          url: 'empty', // empty for grid
          logo: 'cred.png'
        }
      ],
      activeIndex: 0
    }
  },
  methods: {
    changeItem(type) {
      if (type === 'next') this.$refs.carousel.next()
      else if (type === 'prev') this.$refs.carousel.prev()
    },
    activeItem(newIndex) {
      this.activeIndex = newIndex
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/assets/scss/theme/color';
@import '~/assets/scss/main';

.fifth {
  height: auto !important;
  padding-bottom: 148px;
  @include for-phone-portrait {
    padding-right: 60px;
    padding-bottom: 0;
  }
  @include for-phone-landscape {
    padding-right: 60px;
    padding-bottom: 0;
  }
  @include for-tablet-portrait {
    padding-bottom: 0;
  }
  @include for-tablet-landscape {
    padding-bottom: 0;
  }
  @media screen and (device-aspect-ratio: 40/71) {
    padding-right: 20px;
  }
}

.partner-carousel {
  position: relative;
  @include for-phone-portrait {
    margin-top: 60px;
  }
  @include for-phone-landscape {
    margin-top: 60px;
  }
  @include for-tablet-portrait {
    margin-top: 100px;
  }
  .top {
    position: absolute;
    top: -40px;
    right: 16px;
    z-index: 3;
    @include for-phone-portrait {
      top: -10px;
    }
    @include for-phone-landscape {
      top: -10px;
    }
  }
}

.partner-card {
  margin: 10px;
  padding: 14px 14px 10px;
  font-family: 'Overpass';
  color: $--color-nero;
  .testimonial {
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 24px;
    position: relative;
    z-index: 1;
    @include for-phone-portrait {
      font-size: 14px;
      line-height: 20px;
    }
    @include for-phone-landscape {
      font-size: 14px;
      line-height: 20px;
    }
    &::after,
    &::before {
      color: $--color-slate-light-2;
      font-size: 86px;
      position: absolute;
      z-index: -1;
    }
    &::after {
      right: -20px;
      bottom: -30px;
      content: '”';
    }
    &::before {
      left: -20px;
      top: 10px;
      content: '“';
    }
  }
  .person-info {
    display: flex;
    .image {
      margin-right: 16px;
      height: 64px;
      width: 64px;
    }
    .status {
      color: $--color-slate;
      font-size: 12px;
      line-height: 18px;
      .name {
        font-weight: bold;
      }
    }
  }
}
.partner-navigation {
  position: relative;
  z-index: 2;
  margin: 0 10px;
  padding-right: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  @include for-phone-portrait {
    top: 10px;
  }
  @include for-phone-landscape {
    top: -30px;
  }
  @include for-tablet-portrait {
    top: 5px;
  }
  @include for-tablet-landscape {
    top: 5px;
  }
  .hading {
    width: 100%;
    display: flex;
    justify-content: center;
    color: $--color-slate;
    font-size: 12px;
    font-weight: 200;
    line-height: 18px;
  }
  .partner-items {
    margin-top: 12px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 48px;
    @include for-phone-portrait {
      padding: 0;
    }
    @include for-phone-landscape {
      padding: 0;
    }
    @include for-tablet-portrait {
      padding: 0;
    }
    @include for-tablet-landscape {
      padding: 0;
    }
    @include for-ipad-pro-landscape {
      padding: 0;
    }
  }
  .item {
    width: 120px;
    box-shadow: none;
    margin: 10px;
    @include for-tablet-landscape {
      margin: 5px;
    }
    .card {
      background-color: #fff;
      padding: 16px;
      height: 68px;
      border-radius: 10px;
      cursor: pointer;
      position: relative;
      top: 0;
      transition: all 0.2s ease-in-out;
      border: none;
      display: flex;
      align-items: center;
      &:hover {
        top: -4px;
        box-shadow: 2px 2px 16px $--color-slate-light-3;
        transition: all 0.2s ease-in-out;
      }
      &:focus {
        border: none;
        outline: none;
      }
      &:active {
        background-color: $--color-gray-primary;
        box-shadow: none;
        border: none;
      }
      @include for-phone-portrait {
        padding: 12px;
        height: 50px;
      }
    }
    .logo {
      height: 30px;
      width: 100%;
    }
  }
}
.item-navigate {
  position: relative;
  top: -40px;
  margin: 0 10px;
  width: 120px;
  @include for-phone-portrait {
    top: -8px;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .el-button {
    margin: 0;
    padding: 6px;
    font-size: 24px;
    .material-design-icon > .material-design-icon__svg {
      bottom: 0;
    }
  }
}
.site-info {
  position: absolute;
  bottom: 23px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $--color-slate-light-9;
  font-size: 12px;
  line-height: 18px;
  font-weight: 300;
  width: calc(100% - 80px);
  margin-left: -95px;
  @include for-phone-portrait {
    width: 100%;
    margin: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: unset;
    margin-top: 24px;
  }
  @include for-phone-landscape {
    width: 100%;
    margin: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: unset;
    margin-top: 24px;
  }
  @include for-tablet-portrait {
    width: calc(100% - 140px);
    margin: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: unset;
    margin-top: 24px;
  }
  .copyright {
    @include for-phone-portrait {
      width: 100%;
      padding: 16px 0;
      position: absolute;
      bottom: -4px;
    }
    @include for-phone-landscape {
      width: 100%;
      padding: 16px 0;
      position: absolute;
      bottom: -4px;
    }
    @include for-tablet-portrait {
      width: 100%;
      padding: 16px 0;
      position: absolute;
      bottom: -4px;
    }
  }
  .trademark {
    width: 400px;
    @include for-phone-portrait {
      width: 100%;
    }
    @include for-phone-landscape {
      width: 100%;
    }
    @include for-tablet-portrait {
      width: 100%;
    }
    @include for-tablet-landscape {
      width: 300px;
    }
  }
}
::v-deep .el-carousel__container {
  @include for-phone-portrait {
    height: 440px;
  }
  @media screen and (device-aspect-ratio: 71/40) {
    height: 260px;
  }
}
::v-deep
  .el-carousel__indicator.el-carousel__indicator--horizontal.is-active
  .el-carousel__button {
  background-color: #4292ff;
}
</style>
