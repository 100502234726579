<template>
  <div class="first">
    <el-row class="container">
      <el-col
        :xs="20"
        :sm="20"
        :md="10"
        :lg="8"
        :xl="8"
        data-aos="fade-up"
        data-aos-delay="200"
      >
        <div class="page-title">
          Enabling India's credit growth story
        </div>
        <div class="sub-content">
          <div class="sub-text">
            We enable hypergrowth to the existing financial institutions in
            India.
          </div>
          <nuxt-link :to="'/contactUs'">
            <el-button type="primary" size="large" class="hide-phone">
              Partner With Us
            </el-button>
          </nuxt-link>
        </div>
      </el-col>
      <nuxt-link :to="'/contactUs'">
        <el-button type="primary" class="show-phone">
          Partner With Us
        </el-button>
      </nuxt-link>
      <el-col
        :xs="20"
        :sm="20"
        :md="{ span: 11, offset: 1 }"
        :lg="{ span: 12, offset: 2 }"
        :xl="{ span: 12, offset: 2 }"
        data-aos="zoom-in"
        data-aos-delay="200"
      >
        <div class="banner">
          <div class="blue-box left"></div>
          <el-image
            class="banner-image"
            :src="require('~/assets/images/homeMain.png')"
            fit="contain"
          >
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
          <div class="blue-box top"></div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
@import '~/assets/scss/main';

.banner {
  margin-top: 100px;
  position: relative;
  @include for-phone-portrait {
    margin-top: 70px;
  }
  @include for-phone-landscape {
    margin-top: 40px;
  }
}
.top {
  position: absolute;
  top: -50px;
  right: -30px;
  @include for-phone-portrait {
    top: -20px;
    right: -5px;
  }
  @include for-phone-landscape {
    top: -20px;
    right: -5px;
  }
}
.left {
  position: absolute;
  bottom: 90px;
  left: -50px;
  @include for-phone-portrait {
    bottom: 40px;
    left: -20px;
  }
  @include for-phone-landscape {
    bottom: 40px;
    left: -20px;
  }
}
</style>
