<template>
  <div class="fourth">
    <el-row>
      <el-col :xs="20" :sm="20" :md="13" :lg="10" :xl="10">
        <div class="page-title" data-aos="fade-up">
          Our track record across India so far
        </div>
        <el-row>
          <figures
            v-for="(doc, index) in records"
            :key="index"
            :img="doc.filename"
            :figure="doc.figures"
            :description="doc.description"
            data-aos="fade-up"
            class="track-tile"
          />
        </el-row>
      </el-col>
      <el-col
        :xs="24"
        :sm="24"
        :md="{ span: 9, offset: 1 }"
        :lg="{ span: 12, offset: 2 }"
        :xl="{ span: 12, offset: 2 }"
      >
        <div class="map" data-aos="zoom-in">
          <el-image
            :src="require(`~/assets/images/records/map.svg`)"
            fit="contain"
          >
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Figures from '~/components/HomePage/Helper/trackHelper.vue'
export default {
  components: {
    Figures
  },
  data() {
    return {
      records: [
        {
          filename: 'account-multiple-check-outline.svg',
          figures: '30,000+',
          description: 'Lives empowered'
        },
        {
          filename: 'finance.svg',
          figures: '350+ Cr',
          description: 'Finance enabled'
        },
        {
          filename: 'map-marker.svg',
          figures: '50+',
          description: 'Cities covered'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/assets/scss/main';

.track-tile {
  margin-bottom: 24px;
}
.map {
  display: flex;
  justify-content: center;
  @include for-phone-portrait {
    height: 300px;
  }
  @include for-phone-landscape {
    height: 350px;
    width: 250px;
    position: absolute;
    right: 50px;
    top: 120px;
  }
  @include for-tablet-portrait {
    height: 500px;
  }
  @include for-tablet-landscape {
    margin-top: 100px;
  }
}
</style>
