<template>
  <div class="third">
    <el-row>
      <el-col :xs="20" :sm="20" :md="10" :lg="8" :xl="8" data-aos="fade-up">
        <div class="page-title">
          Our keen product interests
        </div>
        <div class="sub-content">
          <div class="sub-text">
            Open to partnering on a diverse set of products to offer value to
            end customers enabling their growth and working capital
            requirements.
          </div>
          <nuxt-link :to="'/contactUs'">
            <el-button type="primary" size="large" class="hide-phone">
              Partner With Us</el-button
            >
          </nuxt-link>
        </div>
      </el-col>
      <nuxt-link :to="'/contactUs'">
        <el-button type="primary" class="show-phone">
          Partner With Us
        </el-button>
      </nuxt-link>
      <el-col
        :xs="24"
        :sm="24"
        :md="{ span: 12, offset: 1 }"
        :lg="{ span: 14, offset: 1 }"
        :xl="{ span: 14, offset: 1 }"
      >
        <div
          class="prod-content"
          data-aos="fade-left"
          data-aos-delay="500"
          data-aos-duration="500"
        >
          <template>
            <div class="blue-box top"></div>
            <el-carousel
              ref="carousel"
              class="product-carousel"
              indicator-position="outside"
              arrow="never"
              trigger="click"
              :interval="8000"
            >
              <el-carousel-item v-for="(item, index) in data" :key="index">
                <div class="car-item">
                  <div class="product-tile">
                    <el-image
                      class="image"
                      :src="
                        require(`~/assets/images/prod-carausel/${item.img66}`)
                      "
                      fit="contain"
                    >
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                    </el-image>
                    <div class="caption">{{ item.cap66 }}</div>
                  </div>
                  <div class="product-tile">
                    <el-image
                      :src="
                        require(`~/assets/images/prod-carausel/${item.img67}`)
                      "
                      fit="contain"
                    >
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                    </el-image>
                    <div
                      v-for="line in item.cap67.split('\n')"
                      :key="line"
                      class="caption"
                    >
                      {{ line }}
                    </div>
                  </div>
                  <div class="product-tile">
                    <el-image
                      :src="
                        require(`~/assets/images/prod-carausel/${item.img68}`)
                      "
                      fit="contain"
                    >
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                    </el-image>
                    <div
                      v-for="line in item.cap68.split('\n')"
                      :key="line"
                      class="caption"
                    >
                      {{ line }}
                    </div>
                  </div>
                  <div class="product-tile">
                    <el-image
                      :src="
                        require(`~/assets/images/prod-carausel/${item.img69}`)
                      "
                      fit="contain"
                    >
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                    </el-image>
                    <div class="caption">{{ item.cap69 }}</div>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
            <div class="item-navigate">
              <el-button
                type="text-black"
                btn-min-width="false"
                @click="changeItem('prev')"
              >
                <chevron-left />
              </el-button>
              <el-button
                type="text-black"
                btn-min-width="false"
                @click="changeItem('next')"
              >
                <chevron-right />
              </el-button>
            </div>
          </template>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: [
        {
          img66: 'Group 66.png',
          cap66: 'Consumer Lending',
          img67: 'Group 67.png',
          cap67: 'Unsecured Business Loan',
          img68: 'Group 68.png',
          cap68: 'Secured Business Loan',
          img69: 'Group 69.png',
          cap69: 'Paylater'
        },
        {
          img66: 'Group 72.png',
          cap66: 'Invoice Financing',
          img67: 'Group 73.png',
          cap67: 'Checkout Finance',
          img68: 'Group 74.png',
          cap68: 'Supply Chain Finance',
          img69: 'Group 75.png',
          cap69: 'Merchant Cash Advance'
        }
      ]
    }
  },
  methods: {
    changeItem(type) {
      if (type === 'next') this.$refs.carousel.next()
      else if (type === 'prev') this.$refs.carousel.prev()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/assets/scss/main';

.prod-content {
  margin-top: 100px;
  position: relative;
  @include for-phone-landscape {
    margin-top: 40px;
  }
  .item-navigate {
    position: relative;
    top: -40px;
    .el-button {
      margin: 0;
      padding: 6px;
      font-size: 24px;
      .material-design-icon > .material-design-icon__svg {
        bottom: 0;
      }
    }
  }
}

.car-item {
  display: flex;
  justify-content: space-between;
  & > .product-tile {
    width: 25%;
    margin-right: 8px;
    image {
      height: 242px;
    }
  }
}

.caption {
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  margin-top: 8px;
  @include for-phone-portrait {
    font-size: 10px;
    line-height: 14px;
  }
  @include for-phone-landscape {
    font-size: 13px;
    line-height: 18px;
  }
  @include for-iphone-x-landscape {
    font-size: 14px;
    line-height: 20px;
  }
}

.top {
  position: absolute;
  top: -40px;
  left: 228px;
  @include for-phone-portrait {
    top: -20px;
    left: 100px;
  }
  @include for-phone-landscape {
    top: -20px;
    left: 228px;
  }
}
::v-deep .el-carousel__indicator.el-carousel__indicator--horizontal {
  margin-top: 16px;
  @include for-phone-landscape {
    margin-top: 0px;
  }
}
::v-deep
  .el-carousel__indicator.el-carousel__indicator--horizontal.is-active
  .el-carousel__button {
  background-color: #4292ff;
}

::v-deep .el-carousel__container {
  @include for-phone-portrait {
    height: 150px;
  }
  @include for-phone-landscape {
    height: 250px;
  }
}
::v-deep .el-carousel__item.is-animating {
  transition: transform 0.8s ease-in-out;
}
</style>
