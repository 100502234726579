<template>
  <div id="mainView" class="container">
    <SideNavigation
      class="side-nav"
      mode="auto"
      :show-active-bar="true"
      :show-bar-strip="true"
      :height-per-tab="30"
      :active-tab-index="currentActiveKeyIndex"
      :labels="getLabels"
      active-tab-color="#2974db"
      inactive-tab-color="#7C8099"
      active-tab-bg-color="transparent"
      inactive-tab-bg-color="transparent"
      active-bar-bg-color="#4292FF"
      bar-strip-bg-color="#CBCCD6"
      container-bg-color="transparent"
      @tabChange="onTabChange"
    />
    <div class="green-box">
      <shape />
      <div class="blue-box"></div>
    </div>
    <div class="yellow-box">
      <div class="blue-box"></div>
      <shape type="yellow" />
    </div>
    <div class="turquoise-box">
      <shape type="turquoise" />
      <div class="blue-box"></div>
    </div>
    <div class="pink-box">
      <shape type="pink" />
    </div>
    <introduction id="description" class="section"></introduction>
    <business id="business" class="section"></business>
    <products id="products" class="section"></products>
    <records id="track" class="section"></records>
    <partners id="partnership" class="section"></partners>
    <Footer class="only-small-screen" size="mini" :min-width="false" />
    <scroll-to-top class="scroll-to-top" />
  </div>
</template>
<script>
import Introduction from '~/components/HomePage/description'
import Business from '~/components/HomePage/business'
import Products from '~/components/HomePage/products'
import Records from '~/components/HomePage/trackRecords'
import Partners from '~/components/HomePage/partners'
import ScrollToTop from '~/components/shared/scrollToTop.vue'
import Shape from '~/components/shape/bgShape'
import SideNavigation from '~/components/InnerSideNavigation/SideNavigation'
import Footer from '~/components/layout/footer'

export default {
  components: {
    Introduction,
    Business,
    Products,
    Records,
    Partners,
    ScrollToTop,
    Shape,
    SideNavigation,
    Footer
  },
  data() {
    return {
      pageBg: [
        {
          className: 'green',
          imageName: 'green.svg'
        },
        {
          className: 'yellow',
          imageName: 'yellow.svg'
        },
        {
          className: 'turquoise',
          imageName: 'turquoise.svg'
        },
        {
          className: 'pink',
          imageName: 'pink.svg'
        }
      ],
      labels: ['description', 'business', 'products', 'track', 'partnership'],
      idList: ['description', 'business', 'products', 'track', 'partnership'],
      currentActiveKeyIndex: 0,
      elementTopPosition: null,
      stopAutoScroll: false,
      isScrolling: null
    }
  },
  computed: {
    getLabels() {
      return this.labels
    },
    getLabelsId() {
      return this.idList
    }
  },
  mounted() {
    this.setResizeObserver()
    this.changeSectionOnScroll()
    window.addEventListener('scroll', this.changeSectionOnScroll)
    const self = this
    window.addEventListener(
      'scroll',
      function(event) {
        window.clearTimeout(self.isScrolling)

        self.isScrolling = setTimeout(function() {
          // console.log('Scrolling has stopped.')
          self.stopAutoScroll = false
        }, 100)
      },
      false
    )
  },
  beforeDestroy() {
    if (this.showSideBar) {
      window.removeEventListener('scroll', this.changeSectionOnScroll)
    }
  },
  methods: {
    onTabChange(evt) {
      this.stopAutoScroll = true
      this.scrollToTargetAdjusted(evt.index)
    },
    scrollToTargetAdjusted(index) {
      const element = document.getElementById(this.getLabelsId[index])
      const offsetPosition = element.offsetTop

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      })
      setTimeout(() => {
        this.currentActiveKeyIndex = index
        // this.stopAutoScroll = false
      }, 200)
    },
    changeSectionOnScroll() {
      // let labelsCount = this.labels.length;
      // !this.elementTopPositio is for when the first applicant doesnt have bureau file
      if (this.stopAutoScroll || !this.elementTopPosition) return
      // console.log(
      //   'scrolling captured in function',
      //   document.documentElement.scrollTop
      // )
      const elementPosition = document.documentElement.scrollTop
      let nextActiveElement = -1
      switch (true) {
        case elementPosition < this.elementTopPosition[1] / 2:
          nextActiveElement = 0
          break
        case elementPosition <
          (this.elementTopPosition[1] + this.elementTopPosition[2]) / 2:
          nextActiveElement = 1
          break
        case elementPosition <
          (this.elementTopPosition[2] + this.elementTopPosition[3]) / 2:
          nextActiveElement = 2
          break
        case elementPosition <
          (this.elementTopPosition[3] + this.elementTopPosition[4]) / 2:
          nextActiveElement = 3
          break
        case elementPosition < this.elementTopPosition[4]:
          nextActiveElement = 4
          break
      }
      if (
        nextActiveElement === -1 ||
        nextActiveElement === this.currentActiveKeyIndex
      )
        return
      this.currentActiveKeyIndex = nextActiveElement
    },
    setResizeObserver() {
      const elem = document.getElementById('mainView')
      this.caluclateElementHeight()
      const resizeObserver = new ResizeObserver(() => {
        this.caluclateElementHeight()
      })
      resizeObserver.observe(elem)
    },
    caluclateElementHeight() {
      try {
        const elHeights = []
        for (const elId of this.getLabelsId) {
          elHeights.push(document.getElementById(elId).offsetTop)
        }
        this.elementTopPosition = elHeights
      } catch (e) {}
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/assets/scss/main';

.container {
  padding-left: 149px;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  @include for-phone-portrait {
    padding-left: 72px;
    padding-bottom: 90px;
  }
  @include for-phone-landscape {
    padding-left: 72px;
    padding-bottom: 40px;
  }
  @include for-tablet-portrait {
    padding-bottom: 90px;
  }
  @include for-tablet-landscape {
    padding-bottom: 90px;
  }
  @include for-iphone-x-landscape {
    padding-bottom: 110px;
  }
}

.container .only-small-screen {
  display: none;
  @include for-phone-portrait {
    display: block;
    &.footer {
      position: absolute;
      left: 60px;
      bottom: 40px;
      display: flex;
      justify-content: flex-start;
      width: 100%;
      margin-left: -60px;
      align-items: flex-end;
    }
  }
  @include for-phone-landscape {
    display: block;
    &.footer {
      position: absolute;
      left: 42px;
      bottom: 50px;
      display: flex;
      justify-content: flex-start;
      width: 100%;
      margin-left: -60px;
      align-items: flex-end;
    }
  }
  @include for-tablet-portrait {
    display: block;
    &.footer {
      position: absolute;
      left: 60px;
      bottom: 20px;
      display: flex;
      justify-content: flex-start;
      width: 100%;
      margin-left: -60px;
      align-items: flex-end;
    }
  }
}

.side-nav {
  position: fixed !important;
  left: 64px;
  top: 40%;
  z-index: 3;
  @include for-phone-portrait {
    display: none;
  }
  @include for-phone-landscape {
    display: none;
  }
  @include for-tablet-portrait {
    display: none;
  }
  @include for-tablet-landscape {
    display: none;
  }
}

::v-deep .side-nav-panes {
  margin-left: -4px !important;
}

::v-deep .side-nav .active-bar-strip {
  z-index: -1;
}

::v-deep .side-nav .side-nav-panes .side-nav-link {
  width: 11px;
  text-indent: -1000em;
  z-index: 3;
}

.section {
  padding-top: 140px;
  height: 100vh;
  // border: 1px solid red;
  &.first {
    @include for-tablet-portrait {
      padding-top: 120px;
    }
  }
  @include for-phone-portrait {
    padding-top: 60px;
    height: auto;
  }
  @include for-phone-landscape {
    padding-top: 60px;
    height: auto;
  }
  @include for-tablet-portrait {
    height: auto;
  }
  @include for-tablet-landscape {
    height: auto;
  }
}

.green-box {
  position: absolute;
  // top: 0;
  // right: 0;
  top: 330px;
  right: -330px;
  width: 663px;
  height: 788px;
  @include for-phone-portrait {
    top: 380px;
    right: -200px;
    width: 293px;
    height: 418px;
  }
  @include for-phone-landscape {
    top: 490px;
    right: -160px;
    width: 293px;
    height: 418px;
  }
  @include for-tablet-portrait {
    top: 670px;
    right: -500px;
    height: 528px;
  }
  @include for-tablet-landscape {
    top: 330px;
    right: -460px;
    width: 663px;
    height: 628px;
  }
  .blue-box {
    position: absolute;
    bottom: 130px;
    left: 0;
    @include for-phone-portrait {
      bottom: 205px;
      left: -30px;
    }
    @include for-phone-landscape {
      bottom: 205px;
      left: -30px;
    }
    @include for-tablet-portrait {
      bottom: 40px;
      left: -50px;
    }
    @include for-tablet-landscape {
      bottom: 140px;
      left: -40px;
    }
  }
}
.yellow-box {
  position: absolute;
  left: -230px;
  top: 1450px;
  width: 541px;
  height: 513px;
  @include for-phone-portrait {
    top: 1220px;
    left: -220px;
    width: 263px;
    height: 388px;
  }
  @include for-phone-landscape {
    top: 1300px;
    left: -200px;
    width: 263px;
    height: 388px;
  }
  @include for-tablet-portrait {
    top: 1650px;
    left: -370px;
  }
  @include for-tablet-landscape {
    top: 920px;
    left: -370px;
  }
  .blue-box {
    position: absolute;
    top: 40px;
    right: 40px;
    @include for-phone-portrait {
      top: 0;
      right: 0;
    }
    @include for-phone-landscape {
      top: 0;
      right: 0;
    }
  }
}
.turquoise-box {
  position: absolute;
  top: 1940px;
  right: -390px;
  width: 666px;
  height: 609px;
  @include for-phone-portrait {
    top: 1705px;
    right: -240px;
    width: 296px;
    height: 239px;
  }
  @include for-phone-landscape {
    top: 1795px;
    right: -240px;
    width: 296px;
    height: 239px;
  }
  @include for-tablet-portrait {
    top: 2350px;
    right: -460px;
  }
  @include for-tablet-landscape {
    top: 1510px;
    right: -520px;
  }
  .blue-box {
    position: absolute;
    bottom: 130px;
    left: 60px;
    @include for-phone-portrait {
      bottom: -15px;
      left: 30px;
    }
    @include for-phone-landscape {
      bottom: -15px;
      left: 30px;
    }
    @include for-tablet-portrait {
    }
  }
}
.pink-box {
  position: absolute;
  top: 3179px;
  left: -80px;
  width: 334px;
  height: 292px;
  @include for-phone-portrait {
    top: 2259px;
    left: -153px;
    width: 234px;
    height: 192px;
  }
  @include for-phone-landscape {
    top: 2379px;
    left: -153px;
    width: 234px;
    height: 192px;
  }
  @include for-tablet-portrait {
    top: 3420px;
    left: -183px;
  }
  @include for-tablet-landscape {
    top: 2370px;
    left: -170px;
  }
}

.scroll-to-top {
  position: fixed;
  right: 28px;
  bottom: 84px;
  z-index: 3;
  @include for-phone-portrait {
    bottom: 88px;
    right: 20px;
  }
}
</style>
