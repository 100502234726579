<template>
  <div class="second">
    <el-row>
      <el-col :xs="24" :sm="18" :md="12" :lg="8" :xl="8">
        <div class="page-title" data-aos="fade-up">
          Our key business verticals
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-row class="bz_desc">
        <buz
          v-for="(doc, index) in records"
          :key="index"
          :filename="doc.url"
          :topic="doc.topic"
          :description="doc.description"
          data-aos="fade-up"
          :data-aos-delay="200 * index"
        />
      </el-row>
    </el-row>
  </div>
</template>

<script>
import Buz from '~/components/HomePage/Helper/businessHelper.vue'
export default {
  components: {
    Buz
  },
  data() {
    return {
      records: [
        {
          url: 'bank-outline.svg',
          topic: 'Wholesale Lending',
          description: `Enabling your business growth and addressing your need for finance to fulfil your working capital needs with our customised products.`
        },
        {
          url: 'domain.svg',
          topic: 'SME Co-lending',
          description: `Partnering with NBFC’s, Financial institutions & Fintechs enabling loans towards SME’s in diverse market segments.`
        },
        {
          url: 'account.svg',
          topic: 'Consumer Co-lending',
          description: `Partnering with NBFC’s, Financial institutions & Fintechs enabling loans towards consumers in diverse market segments.`
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/assets/scss/main';

.second {
  @include for-phone-portrait {
    margin-top: 60px;
    padding-right: 60px;
  }
  @include for-phone-landscape {
    margin-top: 60px;
    padding-right: 60px;
  }
}
.bz_desc {
  margin-top: 40px;
  @include for-phone-portrait {
    margin-top: 0px;
  }
  @include for-phone-landscape {
    margin-top: 0px;
  }
  @include for-tablet-landscape {
    padding-right: 40px;
  }
}
</style>
