<template>
  <div
    class="side-nav-link"
    :class="{ 'active-side-nav-link': isActive }"
    @click="onNavClick"
  >
    <slot />
  </div>
</template>
<script>
export default {
  props: {
    index: { type: Number, default: 0 },
    isActive: Boolean
  },
  methods: {
    onNavClick() {
      this.$emit('tabClick', {
        index: this.index
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.side-nav-link {
  cursor: pointer;
}
</style>
