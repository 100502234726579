<template>
  <el-col
    class="bz-tile"
    :xs="24"
    :sm="16"
    :md="{ span: 7, offset: 1 }"
    :lg="{ span: 5, offset: 2 }"
    :xl="{ span: 5, offset: 2 }"
  >
    <div class="bz-vert">
      <el-image
        class="image"
        :src="require(`~/assets/images/business/${filename}`)"
        fit="contain"
      >
        <div slot="error" class="image-slot">
          <i class="el-icon-picture-outline"></i>
        </div>
      </el-image>
      <div class="bz-vert-desc">
        <div class="vert-heading">{{ topic }}</div>
        <div class="text">
          {{ description }}
        </div>
      </div>
    </div>
  </el-col>
</template>

<script>
export default {
  props: {
    filename: { type: String, default: '' },
    topic: { type: String, default: '' },
    description: { type: String, default: '' }
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
@import '~/assets/scss/theme/color';
@import '~/assets/scss/main';

.bz-tile {
  @include for-tablet-portrait {
    padding-right: 32px;
  }
}

.bz-vert {
  margin-bottom: 32px;
  // @include for-phone-portrait {
  //   text-align: center;
  // }
  .image {
    height: 52px;
    width: 52px;
    margin-bottom: 32px;
    @include for-phone-portrait {
      height: 32px;
      width: 32px;
      margin-bottom: 8px;
    }
    @include for-phone-landscape {
      height: 32px;
      width: 32px;
      margin-bottom: 8px;
    }
    @include for-iphone-x-landscape {
      height: 40px;
      width: 40px;
      margin-bottom: 8px;
    }
    @include for-tablet-portrait {
      height: 48px;
      width: 48px;
    }
    @include for-tablet-landscape {
      height: 48px;
      width: 48px;
    }
  }
  .vert-heading {
    font-size: 24px;
    font-weight: 600;
    line-height: 37px;
    margin-bottom: 8px;
    @include for-phone-portrait {
      font-size: 16px;
      line-height: 25px;
    }
    @include for-phone-landscape {
      font-size: 16px;
      line-height: 25px;
    }
    @include for-iphone-x-landscape {
      font-size: 20px;
      line-height: 32px;
    }
  }
  .text {
    font-size: 16px;
    line-height: 25px;
    color: $--color-slate;
    @include for-phone-portrait {
      font-size: 14px;
      line-height: 20px;
    }
    @include for-phone-landscape {
      font-size: 14px;
      line-height: 20px;
    }
    @include for-iphone-x-landscape {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
</style>
